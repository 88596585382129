import React, { useState, useEffect } from "react";
import DataTable from 'react-data-table-component';
import { makeApiRequest } from "../axiosService/apiCall";
import { Modal, Button } from 'react-bootstrap';
import willGrowLogo from '../assets/Image/willgrowlogo.png';

function UserDetails() {
    const [data, setData] = useState([]);

    const [modalShow, setModalShow] = useState(false);
    const [selectedImage, setSelectedImage] = useState('');
  
    const handleImageClick = (imageSrc) => {
      setSelectedImage(imageSrc);
      setModalShow(true);
    };

    const getUserDetails = async () => {
        try {
            let params = {
                url: `/userDetails`,
                method: 'GET',
            };
            let response = await makeApiRequest(params);
            // console.log("response--", response);
            if (response.status === true) {
                const Userlist = response.data;
                setData(Userlist);
            } else {
                setData([]);
            }
        } catch (error) {
            console.log('error', error);
        }
    };

    useEffect(() => {
        getUserDetails();
    }, []);

    const columns = [
        {
            name: 'S.No',
            selector: (row, index) => index + 1,
            sortable: false,
            width: '70px'
        },
        {
            name: 'Name',
            selector: row => row.name,
            sortable: true,
        },
        {
            name: 'Email',
            selector: row => row.email,
            sortable: true,
        },
        {
            name: 'Age',
            selector: row => row.age,
            sortable: true,
        },
        {
            name: 'Gender',
            selector: row => row.gender,
            sortable: true,
        },
        
        {
            name: 'Aadhar Front',
            selector: row => row.aadharFrontImage,
            cell: row => <img src={row.aadharFrontImage} alt="Adhar Front Image"   onClick={() => handleImageClick(row.aadharFrontImage)}  style={{ width: '50px', height: '50px', cursor: 'pointer'}} />,
            sortable: false,
        },
        {
            name: 'Aadhar Back',
            selector: row => row.aadharBackImage,
            cell: row => <img src={row.aadharBackImage} alt="Adhar Back Image" onClick={() => handleImageClick(row.aadharBackImage)} style={{ width: '50px', height: '50px', cursor: 'pointer'}} />,
            sortable: false,
        },
        {
            name: 'DL',
            selector: row => row.dlImage,
            cell: row => <img src={row.dlImage} alt="DL Image"  onClick={() => handleImageClick(row.dlImage)}  style={{ width: '50px', height: '50px', cursor: 'pointer'}} />,
            sortable: false,
        }
    ];

    const customSort = (rows, selector, direction) => {
        return rows.sort((a, b) => {
            if (typeof selector(a) === "string") {
                const aField = selector(a).toLowerCase();
                const bField = selector(b).toLowerCase();

                let comparison = 0;

                if (aField > bField) {
                    comparison = 1;
                } else if (aField < bField) {
                    comparison = -1;
                }
                return direction === 'desc' ? comparison * -1 : comparison;
            } else {
                const aField = selector(a).props.children ? parseFloat(selector(a).props.children) : 0;
                const bField = selector(b).props.children ? parseFloat(selector(b).props.children) : 0;
                let comparison = 0;

                if (aField > bField) {
                    comparison = 1;
                } else if (aField < bField) {
                    comparison = -1;
                }
                return direction === 'desc' ? comparison * -1 : comparison;
            }
        });
    };

    return (
        <div className="App">
            <div className="container-fluid" style={{ marginLeft: "15px" }}>
                <div className="custom-saled-order text-dark">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="py-3 justify-content-center d-flex justify-content-between">
                                <h3 className="component-user text-center ms-lg-0">User Details</h3>
                            </div>
                            <div className="custom-inside-saled-order text-center mt-5">
                                <div className="tab-content" id="pills-tabContent">
                                    <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab" tabIndex="0">
                                        <div className="custom-datascroll">
                                            <DataTable
                                                columns={columns}
                                                data={data}
                                                pagination
                                                persistTableHead
                                                sortFunction={customSort}
                                                paginationPerPage={5}
                                                paginationRowsPerPageOptions={[5, 10, 15, 20]}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Modal show={modalShow} onHide={() => setModalShow(false)} centered >
                                <Modal.Body>
                                    <img src={selectedImage?selectedImage:willGrowLogo}  alt="Preview" style={{ width: '100%', height: 'auto', objectFit: 'contain' }} />
                                </Modal.Body>
                                {/* <Modal.Footer>
                                    <Button variant="secondary" onClick={() => setModalShow(false)}> Close </Button>
                                </Modal.Footer> */}
                            </Modal>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default UserDetails;
